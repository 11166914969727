<template>
  <div id="app">
    <el-row>
      <el-col :span="24" class="title">
        <el-page-header @back="goBack" content="操作日志"> </el-page-header>
      </el-col>
    </el-row>

    <!-- 账号查询 -->
    <el-input
      v-model="accountValue"
      placeholder="请输入要查询的操作账号"
      style="width: 15%; padding: 0px 10px"
    ></el-input>
    <el-button type="success" size="middle" @click="queryAccount"
      >查询</el-button
    >

    <el-table
      :data="tableData"
      border
      style="width: 100%; margin: 10px"
      height="500px"
    >
      <el-table-column
        fixed
        type="index"
        label="序号"
        align="center"
        width="80"
      >
      </el-table-column>
      <el-table-column
        fixed
        prop="name"
        label="操作账号"
        align="center"
        width="130"
      >
      </el-table-column>
      <el-table-column prop="module" label="操作模块" align="center">
      </el-table-column>
      <el-table-column prop="type" label="操作类型" align="center">
      </el-table-column>
      <!-- <el-table-column prop="address" label="登录地址" align="center">
      </el-table-column> -->
      <el-table-column prop="ip" label="IP" align="center"> </el-table-column>
      <el-table-column
        prop="apiName"
        label="接口"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="creatTime"
        label="创建时间"
        align="center"
      ></el-table-column>
    </el-table>

    <el-row>
      <el-pagination
        :current-page.sync="current"
        :page-size="limit"
        :total="total"
        layout="total, prev, pager, next, jumper"
        style="martgin-bottom: 20px"
        @current-change="queryAccount"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import logOperation from "../../api/manageApi/LogOperation";
import logOperationRule from "../../api/manageApi/LogOperationRule";
export default {
  data() {
    return {
      current: 1,
      limit: 5,
      total: 0,

      accountValue: "",
      logRule: [],

      tableData: [],
    };
  },
  created() {
    this.getAllLogRuleInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    // 查询所有日志
    getAllLogRuleInfo() {
      var _this = this;
      logOperationRule.selectAllLogRule().then((res) => {
        _this.logRule = res.data.data.list;
        console.log(_this.logRule);
        _this.queryAccount();
      });
      // logRule
    },

    // 查询按钮
    queryAccount() {
      var _this = this;
      if (this.accountValue == "") {
        logOperation.getLogFromUser(this.current, this.limit).then((res) => {
          this.total = res.data.data.total;
          _this.tableData = res.data.data.rows;
          for (let j in this.tableData) {
            this.tableData[j]["module"] = "";
            this.tableData[j]["type"] = "";
            for (let i in this.logRule) {
              if (
                this.tableData[j]["apiName"].indexOf(this.logRule[i]["name"]) !=
                -1
              ) {
                if (this.logRule[i]["type"] == "操作") {
                  this.tableData[j]["type"] = this.logRule[i]["value"];
                } else {
                  this.tableData[j]["module"] = this.logRule[i]["value"];
                }
              }
              if (
                this.tableData[j]["module"] != "" &&
                this.tableData[j]["type"] != ""
              )
                break;
            }
          }
        });
      } else {
        logOperation
          .getLogFromUserByName(this.accountValue, this.current, this.limit)
          .then((res) => {
            this.total = res.data.data.total;
            _this.tableData = res.data.data.rows;
            for (let j in this.tableData) {
              this.tableData[j]["module"] = "";
              this.tableData[j]["type"] = "";
              for (let i in this.logRule) {
                if (
                  this.tableData[j]["apiName"].indexOf(
                    this.logRule[i]["value"]
                  ) != -1
                ) {
                  if (this.logRule[i]["type"] == "操作") {
                    this.tableData[j]["type"] = this.logRule[i]["value"];
                  } else {
                    this.tableData[j]["module"] = this.logRule[i]["value"];
                  }
                }
                if (
                  this.tableData[j]["module"] != "" &&
                  this.tableData[j]["type"] != ""
                )
                  break;
              }
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.title {
  margin: 10px;
}
.el-pagination {
  position: absolute;
  bottom: 20px !important;
}
</style>